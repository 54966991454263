import axios, { AxiosResponse } from 'axios';
import envConfig from '../config/environment-config';
import { localStorageMethods } from '../pages/portal-patient/common/helpers';
import { IPatientCrendentials, LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';

export function createHeaders(method: string) {
  const routesWithMasterAuthentication = ['patients', 'medicine', 'integration', 'form', 'address', 'accredited', 'schedule', 'nps'];
  if (routesWithMasterAuthentication.includes(method.split('/')[0])) {
    return {
      headers: {
        Authorization: `Bearer ${envConfig.jwtToken}`,
        'Content-Type': 'application/json',
      },
    };
  }

  const credentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
  if (!credentials || !credentials?.access_token) {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  } else {
    return {
      headers: {
        Authorization: `Bearer ${credentials?.access_token}`,
        'Content-Type': 'application/json',
      },
    };
  }
}

export async function authenticatedPostRequest(
  method: string,
  body?: any,
): Promise<AxiosResponse<any, any>> {
  const url = `${envConfig.patientPortalApiUrl}/${method}`;
  const headers = createHeaders(method);
  const res = await axios.post(url, body, headers);
  if (res.status === 401) {
    if (localStorageMethods.getItem(LocalStorageKeysEnum.PATIENT_CREDENTIALS)) {
      localStorageMethods.removeItem(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
      window.location.reload();
    }
  }
  return res;
}

export async function authenticatedGetRequest(
  method: string,
  params?: any,
  queryParams?: any,
): Promise<AxiosResponse<any, any>> {
  const url = `${envConfig.patientPortalApiUrl}/${method}${params ?? ''}`;
  const headers = createHeaders(method);
  const res = await axios.get(url, {
    ...headers,
    params: queryParams,
  });
  if (res.status === 401) {
    if (localStorageMethods.getItem(LocalStorageKeysEnum.PATIENT_CREDENTIALS)) {
      localStorageMethods.removeItem(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
      window.location.reload();
    }
  }
  return res;
}
