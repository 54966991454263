import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { SurveyQuestions } from '../../common/contracts/SurveysInterface';

export function DynamicFields({
  question,
  value,
  onChange,
}: {
  question: SurveyQuestions;
  value: any;
  onChange: (questionID: string, value: any) => void;
}) {
  const [selectedValue, setSelectedValue] = useState(
    value ?? (question.allowMultipleChoices ? [] : null)
  );
  useEffect(() => {
    onChange(question.questionID, selectedValue);
  }, [selectedValue]);

  const handleMultipleChoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: choiceValue, checked } = e.target;

    setSelectedValue((prev: any) => {
      const validChoices = question.possibleChoices?.map((choice) => choice.id) || [];
      const currentChoices = Array.isArray(prev) ? prev : [];

      if (checked) {
        return [...currentChoices, choiceValue].filter(
          (v, i, arr) => validChoices.includes(v) && arr.indexOf(v) === i
        );
      } else {
        return currentChoices.filter((v) => v !== choiceValue);
      }
    });
  };

  const renderDescription = () => {
    return question.description ? <div>{question.description}</div> : null;
  };

  if (question.type === 'MULTIPLE_CHOICE') {
    return (
      <>
        {renderDescription()}
        {question.allowMultipleChoices ? (
          <FormControl component="fieldset">
            <FormGroup>
              {question.possibleChoices?.map((choice) => (
                <FormControlLabel
                  key={choice.id}
                  control={
                    <Checkbox
                      value={choice.id}
                      checked={Array.isArray(selectedValue) && selectedValue.includes(choice.id)}
                      onChange={handleMultipleChoiceChange}
                    />
                  }
                  label={choice.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        ) : (
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedValue || ''}
              onChange={(e) => setSelectedValue(e.target.value)}
            >
              {question.possibleChoices?.map((choice) => (
                <FormControlLabel
                  key={choice.id}
                  value={choice.id}
                  control={<Radio />}
                  label={choice.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </>
    );
  }

  if (question.type === 'ESSAY') {
    return (
      <div>
        {question.description && <div>{question.description}</div>}
        <TextField
          id={question.questionID}
          multiline
          rows={4}
          required={question.required}
          value={value || ''}
          onChange={(e) => onChange(question.questionID, e.target.value)}
          fullWidth
        />
      </div>
    );
  }  

  if (question.type === 'SCORE') {
    const min = question.minValue ?? 0;
    const max = question.maxValue ?? 10;

    return (
      <>
        {renderDescription()}
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={selectedValue}
            onChange={(e) => setSelectedValue(Number(e.target.value))}
          >
            {Array.from({ length: max - min + 1 }, (_, index) => min + index).map((score) => (
              <FormControlLabel
                key={score}
                value={score}
                control={<Radio />}
                label={score.toString()}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  }

  return null;
}
