import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '64vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '100px auto',
    }
  },
  icon: {
    fontSize: '5rem',
    marginX: '2rem',
    color: 'var(--primary-main)',
  },
}));

const PurchasePage: React.FC<any> = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>
        <PhoneInTalkOutlinedIcon className={classes.icon} />
        <Typography variant="h4" component="h1" gutterBottom style={{ marginTop: '30px' }}>
          Em breve o serviço de programar sua compra estará disponível no nosso site.
        </Typography>
        <Typography variant="body1" component="p">
          Precisa de mais informações? Fale conosco, de segunda a sexta feira das 08:00 às 20:00 horas (exceto feriados), através dos canais:
        </Typography>
        <Typography variant="body1" component="p">
          <li>WhatsApp: 11 99202-0448.</li> 
        </Typography>
        <Typography variant="body1" component="p">
          <li>Ligue gratuitamente para 0800 701 6633 e digite a opção 2.</li>
        </Typography>
      </Container>
    </>
  );
};

export default PurchasePage;
