import { ISurveyParametersInterface } from "../../common/contracts/SurveysInterface";
import { AnsweredSurveyFromBeneficiaryParams } from "../contracts/SurveyPopUpnterface";

export function prepareAnsweredSurveyData({
    medicineFormData,
    respostas,
}: {
    medicineFormData?: ISurveyParametersInterface[];
    respostas: { [key: string]: any };
}): AnsweredSurveyFromBeneficiaryParams {
    if (!medicineFormData || medicineFormData.length === 0) {
        throw new Error("medicineFormData está vazio ou indefinido.");
    }

    const formData = medicineFormData.find(
        (item) => item.parametersType?.PbmProgram === "126"
    );

    if (!formData) {
        throw new Error('Nenhum item encontrado com "PbmProgram" igual a "126".');
    }

    const { surveyID, identification, beneficiaryName } = formData;

    const formattedAnswers = Object.entries(respostas).map(([questionID, answer]) => ({
        questionID: Number(questionID),
        answers: Array.isArray(answer)
            ? answer.map((choiceID) => ({
                value: null,
                possibleQuestionChoiceID: Number(choiceID),
                customAnswer: null,
            }))
            : [
                {
                    value: null,
                    possibleQuestionChoiceID: Number(answer),
                    customAnswer: null,
                },
            ],
    }));

    return {
        surveyID: Number(surveyID),
        identification,
        name: null,
        purchaseDate: new Date().toISOString().split("T")[0],
        answers: formattedAnswers,
    };
}
