import { AxiosResponse } from 'axios';
import { authenticatedPostRequest } from './authenticatedRequestService';
import { AnsweredSurveyFromBeneficiaryParams, GetSurveyParams, GetSurveyQuestionsParams, ISurveyParams, ISurveyQuestions } from '../pages/portal-patient/SurveyPopUp/contracts/SurveyPopUpnterface';

async function getSurvey(body: GetSurveyParams): Promise<AxiosResponse<ISurveyParams[]>> {
    const method = 'nps/get-survey';
    const requestBody = {
        ...body,
    };

    try {
        return await authenticatedPostRequest(
            method,
            requestBody,
        );
    } catch (error) {
        throw error;
    }
}

async function getSurveyQuestions(body: GetSurveyQuestionsParams): Promise<AxiosResponse<ISurveyQuestions, any>> {
    const method = 'nps/get-survey-questions';
    const requestBody = {
        ...body,
    };

    return await authenticatedPostRequest(
        method,
        requestBody,
    );
}

async function answeredSurveyFromBeneficiary(body: AnsweredSurveyFromBeneficiaryParams): Promise<AxiosResponse<boolean, any>> {
    const method = 'nps/answered-survey-from-beneficiary';
    const requestBody = {
        ...body,
    };

    return await authenticatedPostRequest(
        method,
        requestBody,
    );
}

export const npsSurveyService = {
    getSurvey,
    getSurveyQuestions,
    answeredSurveyFromBeneficiary
};
