// eslint-disable-next-line
export enum LocalStorageKeysEnum {
    MEDICINE_FORM_DATA = 'MEDICINE_FORM_DATA',
    FIND_ALL_MEDICINES_DATA = 'FIND_ALL_MEDICINES_DATA',
    DYNAMIC_QUESTIONS_BY_PROGRAM = 'DYNAMIC_QUESTIONS_BY_PROGRAM',
    CEP_INTEGRATION = 'CEP_INTEGRATION',
    PATIENT_DATA = 'PATIENT_DATA',
    ADDRESS_INTEGRATION = 'ADDRESS_INTEGRATION',
    ACCREDITED_INTEGRATION = 'ACCREDITED_INTEGRATION',
    PATIENT_CREDENTIALS = 'PATIENT_CREDENTIALS',
    FIND_MEDICINES_BY_PATIENT = 'FIND_MEDICINES_BY_PATIENT',
    FIND_DISCOUNTS_BY_PATIENT = 'FIND_DISCOUNTS_BY_PATIENT',
    FIND_PRODUCTS_AVAILABLE_TO_DISCOUNTS = 'FIND_AVAILABLE_PRODUCTS_TO_DISCOUNTS',
    PATIENT_CPF_LOGIN = 'PATIENT_CPF_LOGIN',
    FIND_DOCTOR_NAME_AND_SPECIALTY = 'FIND_DOCTOR_NAME_AND_SPECIALTY',
    FIND_PROFESSIONAL_TYPES = 'FIND_PROFESSIONAL_TYPES',
    FIND_CRM_PRODUCTS = 'FIND_CRM_PRODUCTS',
    FIND_PROFESSIONALS = 'FIND_PROFESSIONALS',
    FIND_SCHEDULES_BY_PATIENT_FOLLOWUP = 'FIND_SCHEDULES_BY_PATIENT_FOLLOWUP',
    GET_SCHEDULE = 'GET_SCHEDULE',
    SURVEY_PARAMETERS = 'SURVEY_PARAMETERS',
}
