import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import styles from './SurveyPopUp.module.css';
import { npsSurveyService } from '../../../services/npsSurveyService';
import { localStorageMethods } from '../common/helpers';
import { LocalStorageKeysEnum } from '../common/contracts';
import { DynamicFields } from './components/DynamicFields';
import { ISurveyParametersInterface, SurveyQuestions } from '../common/contracts/SurveysInterface';
import { AnsweredSurveyFromBeneficiaryParams, ISurveyQuestions } from './contracts/SurveyPopUpnterface';
import { prepareAnsweredSurveyData } from './helpers/surveyPopUpHandleData';

interface SurveyPopupProps {
  showPopup: boolean;
  onClose: () => void;
}

const SurveyPopup: React.FC<SurveyPopupProps> = ({ showPopup, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<SurveyQuestions[]>([]);
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState<string>("Obrigado por responder nossa pesquisa!");
  const [dataPrepared, setDataPrepared] = useState(false);
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const patientCredentials = localStorageMethods.getItem<ISurveyParametersInterface[] | undefined>(LocalStorageKeysEnum.SURVEY_PARAMETERS) || [];

  const collectAllAnswers = () => {
    const allAnswers = { ...answers };

    questions.forEach((question) => {
      if (!(question.questionID in allAnswers)) {
        allAnswers[question.questionID] = null;
      }
    });

    return allAnswers;
  };

  const answerSurveyData: (paramns: AnsweredSurveyFromBeneficiaryParams) => Promise<void> = async (paramns) => {
    try {
      await npsSurveyService.answeredSurveyFromBeneficiary(paramns);
      localStorageMethods.removeItem(LocalStorageKeysEnum.SURVEY_PARAMETERS);
    } catch (error) {
      console.error("Erro ao enviar respostas da pesquisa:", error);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (!dataPrepared) {
      try {
        const allAnswers = collectAllAnswers();
  
        const preparedData = prepareAnsweredSurveyData({
          medicineFormData: patientCredentials || [],
          respostas: allAnswers,
        });
  
        answerSurveyData(preparedData);
        setDataPrepared(true);
        setSurveyCompleted(true);
      } catch (error) {
        console.error("Erro ao preparar os dados da pesquisa:", error);
      }
    }
  };

  const handleAnswerChange = (questionID: string, value: any) => {
    setAnswers((prev) => ({
      ...prev,
      [questionID]: value,
    }));

    const currentQuestion = questions[currentQuestionIndex];

    if (currentQuestion.description === "Você tem PET?" || currentQuestion.description === "Gostaria de participar de uma pesquisa sobre saúde animal?") {
      const selectedAnswerText = currentQuestion.possibleChoices?.find(
        (choice) => choice.id === value
      )?.label;

      if (selectedAnswerText === "Não") {
        if (currentQuestion.description === "Gostaria de participar de uma pesquisa sobre saúde animal?") {
          setThankYouMessage("Obrigado pelas informações!");
        }

        setSurveyCompleted(true);
        setCurrentQuestionIndex(questions.length);
        return;
      }
    }
  };

  const verifyPatient = async (): Promise<void> => {
    setLoading(true);

    try {
      const surveyParameters = patientCredentials.filter(
        (item) => item.parametersType.PbmProgram === '126'
      );

      const responseQuestions = await Promise.all(
        surveyParameters.map(async (survey) => {
          const response = await npsSurveyService.getSurveyQuestions({
            surveyID: Number(survey.surveyID),
          });

          const surveyQuestionsData: ISurveyQuestions[] = Array.isArray(response.data) ? response.data : [response.data];

          return surveyQuestionsData.flatMap((surveyItem) => {
            return surveyItem.questions.map((q) => ({
              id: q.questionID,
              questionID: q.questionID,
              description: q.description,
              required: q.required,
              type: q.type,
              allowCustomAnswer: q.allowCustomAnswer,
              allowMultipleChoices: q.allowMultipleChoices,
              possibleChoices: q.possibleChoices,
              minValue: q.minValue,
              maxValue: q.maxValue,
            }));
          });
        })
      );

      const flatQuestions = responseQuestions.flat();
      setQuestions(flatQuestions);
    } catch (error) {
      console.error('Erro ao buscar informações do paciente:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showPopup && patientCredentials.length > 0) {
      setShouldShowPopup(true);
      verifyPatient();
    } else {
      setShouldShowPopup(false);
    }
  }, [showPopup]);

  const handleClose = () => {
    if (!dataPrepared) {
      try {
        const allAnswers = collectAllAnswers();
  
        const preparedData = prepareAnsweredSurveyData({
          medicineFormData: patientCredentials || [],
          respostas: allAnswers,
        });
  
        answerSurveyData(preparedData);
        setDataPrepared(true);
      } catch (error) {
        console.error("Erro ao preparar os dados da pesquisa no fechamento:", error);
      }
    }
  
    setShouldShowPopup(false);
    onClose();
  };

  const showTextTitle = (isFirstQuestion: number) => {
    if (isFirstQuestion === 0) {
      return (
        <p>
          Na <strong>Boehringer Ingelheim</strong> somos apaixonados pela saúde humana e animal. 
          E trabalhamos para que você e seu pet tenham qualidade de vida e possam aproveitar momentos especiais juntos! 
          Para entender melhor a rotina de vocês
          e o cuidado que você mais valoriza quando se trata do seu pet, criamos uma pesquisa rápida! 
          Suas respostas nos ajudarão a melhorar nossos serviços e o atendimento para sua família!
        </p>
      );
    }
  }

  return (
    <Dialog
      open={shouldShowPopup}
      aria-labelledby="survey-popup-title"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          margin: '16px',
          maxHeight: '90%',
          borderRadius: '8px',
        },
      }}
    >
      {loading ? (
        <DialogContent
          className={styles.PopupContainer}
          style={{ padding: fullScreen ? '10px' : '20px' }}
        >
          <p>Carregando perguntas...</p>
        </DialogContent>
      ) : surveyCompleted ? (
        <>
          <DialogTitle id="survey-popup-title">{thankYouMessage}</DialogTitle>
          <DialogContent
            className={styles.PopupContainer}
            style={{ padding: fullScreen ? '10px' : '20px' }}
          >
            <div className={styles.ButtonContainer}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Fechar
              </Button>
            </div>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="survey-popup-title">Pesquisa de Saúde Animal</DialogTitle>
          <DialogContent
            className={styles.PopupContainer}
            style={{ padding: fullScreen ? '10px' : '20px' }}
          >

            { showTextTitle(currentQuestionIndex) }

            {questions[currentQuestionIndex] && (
              <DynamicFields
                question={questions[currentQuestionIndex]}
                value={answers[questions[currentQuestionIndex].questionID]}
                onChange={handleAnswerChange}
              />
            )}
            <div className={styles.ButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ fontSize: fullScreen ? '14px' : '16px', padding: fullScreen ? '8px 16px' : '10px 20px' }}
              >
                {currentQuestionIndex < questions.length - 1 ? 'Próxima' : 'Finalizar'}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                style={{ fontSize: fullScreen ? '14px' : '16px', padding: fullScreen ? '8px 16px' : '10px 20px' }}
              >
                Fechar
              </Button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default SurveyPopup;
